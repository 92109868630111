<template>
  <div v-if="!loading" class="flex-container non-scrollable">
    <div class="flex justify-content-between align-items-center">
      <NameHeader :independent="independent"></NameHeader>
      <div class="flex" style="gap: 1rem">
        <Button label="Voir le contrat" class="p-button-rounded" :disabled="!mission.last_document_contract"
                size="small"
                @click="downloadContractFile"/>
        <Button v-if="hasAccess($route.name, true) && !independent.isRhValidated" label="Valider pour Rh" class="p-button-rounded"
                size="small" @click="validateIndependent"/>
        <Button v-if="hasAccess($route.name, true)" label="Enregistrer" class="p-button-rounded"
                size="small" @click="save"/>
        <Button
            v-if="(mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.PENDING_SIGNATURE || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
            :disabled="!mission?.last_document_contract?.metadata?.signatureRequestId || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED"
            :label="mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED ? 'Annulation du contrat en cours' : 'Annuler le contrat en attente de signature'"
            class="p-button-rounded p-button-warning h-auto"
            @click="cancelContractSignatureConfirmation"
        />
        <Button
            v-if="hasAccess($route.name, true) && mission.status === constants.MISSION_STATUS.PENDING_CONTRACT
             && (!mission?.last_document_contract || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
            label="Valider la mission" class="p-button-rounded" size="small"
            @click="validateMissionDialog = true"
        />
      </div>
    </div>
    <div class="scrollable">
      <fiche-mission :mission="mission" @update="mission = $event" @force-mission-update="save"/>
      <div class="spacer"/>
    </div>
    <!--  todo: when some free time, merge use the component dialogFilePreview and set it up properly  -->
    <dialog-contract-upload
        :is-active="openContractImportDialog"
        @closeDialog="openContractImportDialog = false"
        @importContractFile="importContract"
    />
    <dialog-contract-generation
        :is-active="openContractGenerationDialog"
        :missionId="mission.id"
        :mission-contract-metadata="mission.contract_metadata"
        @closeDialog="openContractGenerationDialog = false"
        @contractGenerated="contractGenerated"
    />
    <dialog-validate-mission :is-active="validateMissionDialog"
                             :mission="mission"
                             @import-contract="openContractImportDialog = true"
                             @generate-contract="openContractGenerationDialog = true"
                             @validate-without-contract="validateMission"
                             @cancel-validation="closeValidateMissionDialog"/>
    <dialog-confirm
        :is-active="confirmDialog"
        :refuse-action="closeConfirmDialog"
        :validate-action="confirmDialogValidate"
        :title="confirmDialogTitle"
        :text-info="confirmDialogInfo"
        :text-question="confirmDialogQuestion"
    />
  </div>
  <div v-else class="overflow-hidden flex">
    <ProgressSpinner></ProgressSpinner>
  </div>
</template>

<script>
import FicheMission from "@/components/FicheMission";
import NameHeader from "@/components/NameHeader";
import DialogContractGeneration from "@/components/Mission/DialogContractGeneration";
import DialogContractUpload from "@/components/Mission/DialogContractUpload";
import DialogConfirm from "@/components/DialogConfirm";
import { mapState } from "vuex";
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import DialogValidateMission from "@/components/dialogValidateMission.vue";
import Alert from "@/utils/Alert";
import getErrorKeySignature from "@/utils/GetErrorKeySignature";

export default {
  name: "FicheCreateMission",
  components: {
    DialogValidateMission,
    DialogContractGeneration, DialogContractUpload, FicheMission, NameHeader, DialogConfirm},
  data() {
    return {
      hasAccess: hasAccess,
      independent: null,
      loading: true,
      openContractGenerationDialog: false,
      openContractImportDialog: false,

      confirmDialog: false,
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
      confirmDialogValidate: () => {},

      validateMissionDialog: false
    }
  },
  watch: {
    loading () {
      if (!this.loading) {
        this.$nextTick(() => {
          if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
        })
      }
    }
  },
  computed: {
    ...mapState({
      mission: state => state.mission.mission,
      constants: state => state.constants
    }),
    apiUrl() {
      return process.env.VUE_APP_APIURL
    }
  },
  async created() {
    await this.$store.dispatch('mission/getOneMission', this.$route.params.idMission)
    await this.$store.dispatch('commercial/getAllCommercials')

    if (!this.mission) {
      await this.$router.push({name: 'Mission'})
    }
    this.independent = this.mission.independent
    this.loading = false
  },
  methods: {
    async validateIndependent() {
      this.confirmDialogValidate = async () => {
        this.independent = await this.$store.dispatch('independent/updateIndependent', {
          id: this.mission.independent.id,
          payload: {id: this.mission.independent.id, isRhValidated: true, status: this.constants.INDEPENDENT_STATUS.DRAFT}
        })
        this.closeConfirmDialog()
      }
      this.confirmDialogTitle = 'Transmettre le porté aux ressources humaines'
      this.confirmDialogInfo = 'En validant, vous transmettrez le porté aux rh, le contrat de portage pourra alors être créé.'
      this.confirmDialogQuestion = 'Êtes-vous sûr de transmettre le porté aux Rh ?'
      this.confirmDialog = true
    },
    closeConfirmDialog() {
      this.confirmDialog = false
    },
    async downloadContractFile() {
      let fileO = this.mission.last_document_contract.document

      const file = await this.$store.dispatch('file/downloadFile', fileO)
      const blob = new Blob([new Uint8Array(file.data.data)], {type: file.mimetype})
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
    },
    async save() {
      this.$store.state.misc.loading = true
      await this.$store.dispatch('mission/saveMission', this.mission)
      this.$store.state.misc.loading = false
      this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Mission sauvegardée', life: 3000});
    },
    async importContract(payload) {
      await this.$store.dispatch('mission/importContract', {
        id: this.mission.id,
        form: payload.form
      })
      this.closeValidateMissionDialog()
      await this.$router.push({
        name: 'FicheMissionPorte',
        params: {idPorte: this.mission.independent.id, idMission: this.mission.id}
      })
    },
    contractGenerated () {
      this.closeValidateMissionDialog()
    },
    async validateMission () {
      // change the isCommercialValidated in the mission
      await this.$store.dispatch('mission/commercialValidateMission', {id: this.mission.id})
      this.closeValidateMissionDialog()
    },
    closeValidateMissionDialog () {
      this.validateMissionDialog = false
    },
    cancelContractSignatureConfirmation () {
      if (hasAccess(this.$route.name, true)) {
        this.confirmDialogValidate = async () => {
          await this.cancelContractSignature()
          this.confirmDialog = false
        }
        this.confirmDialogTitle =  'Confirmation Annulation'
        this.confirmDialogQuestion =  'Êtes vous sur d\'annuler la signature du contrat ?'
        this.confirmDialogInfo = 'Le contrat sera supprimé si vous validez et vous pourrez importer ou générer un nouveau contrat'
        this.confirmDialog = true
      }
    },
    async cancelContractSignature () {
      try {
        await this.$store.dispatch('mission/cancelMissionContractSignature', {
          signatureRequestId: this.mission.last_document_contract.metadata.signatureRequestId,
          documentId: this.mission.last_document_contract.id
        })
        await this.$store.dispatch('mission/getOneMission', this.mission.id, { root: true })
        Alert.successMessage(this, 'cancelContractSignature')
      } catch (e) {
        Alert.errorMessage(this, getErrorKeySignature(e))
      }
    }
  }
}
</script>

<style scoped>

</style>
