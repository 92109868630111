<template>
  <Dialog v-model:visible="dialogActive" :draggable="false" :modal="true" :closable="false" class="confirm-dialog"
          content-class="dialog-content">
    <template #header>
      <div style="width: 100%; text-align: center;">
        <i class="pi pi-check-circle text-green-500 text-4xl"></i>
        <h3 class="text-black-alpha-90">Validation de mission</h3>
      </div>
    </template>
    <div class="text-green-500 mt-3" style="text-align: center; font-size: 1.2rem;">
      Vous souhaitez valider la mission ?
    </div>
    <div class="flex flex-row justify-content-center text-center mt-2">
      <p class="w-5">
        Vous pouvez valider la mission en important un contrat ou
        en le générant pour l'envoie en signature éléctronique,
        vous pouvez également reporter cette action à plus tard et valider la mission maintenant
        (celle-ci restera en Creation de mission tant que le contrat ne sera pas importer ou générer)
      </p>
    </div>
    <template #footer>
      <div class="mt-4">
        <Button label="Annuler" class="p-button-text" @click="cancelValidation"/>
        <Button v-if="hasAccess($route.name, true) && mission.status === constants.MISSION_STATUS.PENDING_CONTRACT
                  && (!mission?.last_document_contract || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED)"
                label="Importer un contrat" autofocus
                @click="importContract"/>
        <Button v-if="hasAccess($route.name, true) && mission.status === constants.MISSION_STATUS.PENDING_CONTRACT &&
                   (!mission?.last_document_contract || mission?.last_document_contract?.status === constants.DOCUMENT_STATUS.CANCELLED) &&
                   mission.contractType === constants.CONTRACT_TYPE.INTERNAL"
                label="Générer un contrat" autofocus
                @click="generateContract"/>
        <Button v-if="!mission.isCommercialValidated" label="Valider sans contrat" class="p-button-warning" autofocus @click="validateWithoutContract"/>
      </div>
    </template>
  </Dialog>
</template>

<script>
import {mapState} from "vuex";
import {hasAccess} from "@/utils/Misc";

export default {
  name: "dialogValidateMission",
  emits: ['cancelValidation', 'importContract', 'generateContract', 'validateWithoutContract'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    mission: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    })
  },
  watch: {
    isActive(value) {
      this.dialogActive = value
    }
  },
  data() {
    return {
      dialogActive: false
    }
  },
  methods: {
    hasAccess,
    cancelValidation() {
      this.$emit('cancelValidation')
    },
    importContract() {
      this.$emit('importContract')
    },
    generateContract() {
      this.$emit('generateContract')
    },
    validateWithoutContract() {
      this.$emit('validateWithoutContract')
    }
  }
}
</script>

<style>
.confirm-dialog .dialog-content {
  min-width: 800px;
  max-width: 1280px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}
</style>
